var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"table-responsive"},[_c('table',{class:_vm.customClass,attrs:{"id":_vm.customTableId}},[_c('thead',[_vm._t("header")],2),_c('tbody',[(_vm.loading)?[_vm._m(0)]:_vm._e(),(!_vm.loading && !(_vm.data.length > 0))?[_vm._m(1)]:_vm._e(),(!_vm.loading)?[_vm._l((_vm.withPagination ? _vm.paginatedData : _vm.data),function(item,i){return [_vm._t("defaultBody",null,{"item":item,"i":i,"number":_vm.getPageNumber(i)})]}),(!_vm.loading && _vm.data.length - (_vm.currentPage - 1) * _vm.perPage < 5)?[_c('tr',[_c('div',{staticClass:"text-center",staticStyle:{"height":"15rem"}}),_vm._t("body")],2)]:_vm._e(),[_vm._t("extra-custom-row")]]:_vm._e()],2)])]),(_vm.withPagination)?_c('div',{staticClass:"d-flex justify-content-center mt-5"},[(
        _vm.totalRows < _vm.perPage ||
          _vm.perPage * _vm.currentPage >= _vm.totalRows ||
          _vm.totalRows <= 0
      )?_c('h4',[_vm._v(" Total Data : "+_vm._s(_vm.totalRows)+" / "+_vm._s(_vm.totalRows)+" ")]):_c('h4',[_vm._v("Total Data : "+_vm._s(_vm.perPage * _vm.currentPage)+" / "+_vm._s(_vm.totalRows))]),(_vm.totalRows > 0)?_c('b-pagination',{staticClass:"mt-5 mx-auto",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"pills":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"15"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"15"}},[_c('div',{staticClass:"text-center"},[_c('i',{staticClass:"flaticon2-open-box text-secondary icon-3x"}),_c('h4',[_vm._v(" Empty. ")])])])])
}]

export { render, staticRenderFns }