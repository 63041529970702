<template>
  <div>
    <div class="table-responsive">
      <table :id="customTableId" :class="customClass">
        <thead>
          <slot name="header"></slot>
        </thead>

        <tbody>
          <template v-if="loading">
            <tr>
              <td colspan="15">
                <div class="text-center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
              </td>
            </tr>
          </template>

          <template v-if="!loading && !(data.length > 0)">
            <tr>
              <td colspan="15">
                <div class="text-center">
                  <i class="flaticon2-open-box text-secondary icon-3x"></i>

                  <h4>
                    Empty.
                  </h4>
                </div>
              </td>
            </tr>
          </template>

          <!-- <template v-if="!loading && data.length >= 1 && data.length < 5">
            <tr>
              <div class="text-center" style="height: 15rem;"></div>
            </tr>
          </template> -->

          <template v-if="!loading">
            <template
              v-for="(item, i) in withPagination ? paginatedData : data"
            >
              <slot
                name="defaultBody"
                :item="item"
                :i="i"
                :number="getPageNumber(i)"
              ></slot>
            </template>

            <template
              v-if="!loading && data.length - (currentPage - 1) * perPage < 5"
            >
              <tr>
                <div class="text-center" style="height: 15rem;"></div>
                <slot name="body"></slot>
              </tr>
            </template>

            <template>
              <slot name="extra-custom-row"></slot>
            </template>
          </template>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-center mt-5" v-if="withPagination">
      <h4
        v-if="
          totalRows < perPage ||
            perPage * currentPage >= totalRows ||
            totalRows <= 0
        "
      >
        Total Data : {{ totalRows }} / {{ totalRows }}
      </h4>
      <h4 v-else>Total Data : {{ perPage * currentPage }} / {{ totalRows }}</h4>
      <b-pagination
        class="mt-5 mx-auto"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        pills
        v-if="totalRows > 0"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompleteTable",
  props: {
    data: {
      default: () => [],
      type: Array,
    },
    loading: {
      default: true,
      type: Boolean,
    },
    perPage: {
      default: 10,
      type: Number,
    },
    customTableId: {
      default: "table",
    },
    customClass: {
      default:
        "table table-head-custom table-vertical-center table-head-bg table-borderless mb-5",
    },
    withPagination: {
      default: true,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    paginatedData() {
      const max = this.currentPage * this.perPage;

      const min = max - this.perPage;

      return this.data.filter((item, index) => {
        if (index + 1 <= max && index + 1 > min) {
          return item;
        }
      });
    },
    totalRows() {
      return this.data.length;
    },
  },
  methods: {
    getPageNumber(i) {
      if (!(this.currentPage > 1)) {
        return i + 1;
      }

      if (this.currentPage > 1) {
        const number = this.perPage * this.currentPage;

        return number - 10 + (i + 1);
      }
    },
  },
};
</script>
