<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Code</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Code"
              v-model="filter.code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Station</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Station"
              v-model="filter.name"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click="getStations(true)">
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom card-stretch-fourth p-5 gutter-b">
      <div class="d-flex my-3 justify-content-start">
        <button
          class="btn btn-success btn-md"
          @click="showInputModal(null, 'Form Add Station')"
        >
          <i class="flaticon-add"></i> Add Station
        </button>
      </div>

      <div class="card-body p-0">
        <complete-table :loading="loading" :data="stations">
          <template #header>
            <tr class="text-left">
              <th class="pl-7">Code</th>
              <th class="pl-7">Station</th>
              <th class="pl-7 text-center">Action</th>
            </tr>
          </template>

          <template #defaultBody="{ item: station, i }">
            <tr v-bind:key="i" class="border-0">
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ station.code }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ station.name }}
                </span>
              </td>

              <td class="pl-0 pt-8 text-center">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="showInputModal(station, 'Form Update Station', false)"
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="showDeleteModal(station)"
                >
                  <i class="flaticon-delete"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <b-modal ref="input" hide-footer :title="modalTitle">
      <div class="d-block text-center">
        <form>
          <div class="row">
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Code *</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Code"
                v-model="form.code"
              ></b-form-input>
            </div>
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Station *</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Station Name"
                v-model="form.name"
              ></b-form-input>
            </div>
          </div>
        </form>

        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            block
            @click="isCreate ? saveStation() : updateStation()"
          >
            Save
          </b-button>

          <b-button
            :disabled="loading"
            class="btn btn-secondary mt-0"
            block
            @click="closeInputModal"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal ref="delete" hide-footer title="Warning">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Are You Sure Want To Delete Station</label>
          </div>
        </div>
        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            @click="deleteStation"
            block
          >
            Delete
          </b-button>

          <b-button
            class="btn btn-secondary mt-0"
            block
            @click="closeDeleteModal"
            :disabled="loading"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import {
  DELETE_STATION,
  GET_STATION,
  SAVE_STATION,
  UPDATE_STATION,
} from "../../../core/services/store/station.module";

export default {
  components: { CompleteTable },

  data() {
    return {
      filter: {
        code: null,
        name: null,
      },
      currentItem: null,
      modalTitle: null,
      form: {
        code: null,
        name: null,
      },
      form_update: {
        station_old: {
          code: null,
          name: null,
        },
        station_new: {
          code: null,
          name: null,
        },
      },
      isCreate: true,
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.stations.loading,
      stations: (state) => state.stations.stations?.data,
    }),
  },

  methods: {
    showInputModal(station, modalTitle, isCreate = true) {
      this.currentItem = station;

      this.form = {
        code: station?.code,
        name: station?.name,
      };

      if (!isCreate) {
        this.form_update = {
          station_old: {
            code: station?.code,
            name: station?.name,
          },
          station_new: {
            code: station?.code,
            name: station?.name,
          },
        };
      }

      this.modalTitle = modalTitle;

      this.isCreate = isCreate;

      this.$refs["input"].show();
    },

    showDeleteModal(station) {
      this.currentItem = station;

      this.$refs["delete"].show();
    },

    closeInputModal() {
      this.$refs["input"].hide();
    },

    closeDeleteModal() {
      this.$refs["delete"].hide();
    },

    getStations(filter = false) {
      if (!arrayMoreThanOne(this.stations) || filter) {
        this.$store.dispatch(GET_STATION, {
          params: this.filter,
        });
      }
    },

    deleteStation() {
      this.$store
        .dispatch(DELETE_STATION, {
          code: this.currentItem?.code,
        })
        .then(() => {
          this.closeDeleteModal();
        });
    },

    saveStation() {
      this.$store.dispatch(SAVE_STATION, this.form).then(() => {
        this.closeInputModal();
      });
    },

    updateStation() {
      this.form_update.station_new = this.form;
      this.$store.dispatch(UPDATE_STATION, this.form_update).then(() => {
        this.closeInputModal();
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Station " }]);

    this.getStations();
  },
};
</script>
