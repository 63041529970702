export function arrayMoreThanOne(array) {
  return array?.length > 0;
}

export function formattedOptions(
  data,
  defaultValue = {
    value: null,
    text: "Choose Option"
  }
) {
  let options = [];

  if (arrayMoreThanOne(data)) {
    options = data.map(scope => {
      return {
        value: scope.code,
        text: scope.name
      };
    });
  }

  return [{ value: defaultValue.value, text: defaultValue.text }, ...options];
}

export function findOnArray(data, column, value) {
  return data.find((item) => item[column] === value);
}
